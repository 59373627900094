/*******************************************************************************
*
* _mixins-links.scss
*
******************************************************************************/

@mixin noLink{
	border: none;
	text-decoration: none;
	background: none;
	padding: 0;
	color: $text-color;
	font-weight: normal;
	padding: 0;
	letter-spacing: $letter-spacing-base;
	margin: 0;
	&:hover {
		border: none !important;
		text-decoration: none;
	}
}


@mixin link{
	color: $color4;
	transition: all 0.5s ease 0s;
	&:hover {
		color: $color3;
		text-decoration: none;
	}
}

@mixin link-arrow-light {
	color: $color4;
}
