/* CSS Document */


.modal {
	width: 100%;
	.modal-content {
		border: none;
		background:#190e07 !important;
		color: white;
		border-radius: 0;
	}
	.modal-header {
		border: none;
	}

	.close {
		color: white;
	}

	
}