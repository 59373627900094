/*******************************************************************************
*
* _default.settings.scss
*
******************************************************************************/

html, body {
	width:100%;
	height:100%;
	padding:0;
	margin:0;

	min-width:320px;
	background: #30241c url("../images/bg_xs.jpg") repeat center center;
	background-size: cover;
	position: fixed;
	overflow: hidden;
	-ms-user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	&::selection {
		background: transparent;
		color: inherit;
	}
	@media(min-width:$screen-xs) {
		background: #30241c url("../images/bg_small.jpg") repeat center center;
	}
	@media(min-width:$screen-sm) {
		background: #30241c url("../images/bg_medium.jpg") repeat center center;
		position: relative;
	}
	@media(min-width:$screen-md) {
	}
	@media(min-width:$screen-lg) {
		background: #30241c url("../images/bg.jpg") repeat center center;
	}


}

.page {




}

header {

	position: absolute;
	top:0;
	z-index: 2;
	width: 100%;
	padding-top: 10px;
	height:100px;
	background: transparent url("../images/header.png") repeat-x center top;


	@media(min-width:$screen-sm) {
		padding-top: 20px;
	}
	h1 {
		text-align: center;
		font-size: 25px;
		margin: 0;
		color: white;
		text-shadow:-2px -2px 4px rgba(0,0,0, 0.5);
		@media(min-width:$screen-xs) {
			font-size: 30px;
		}
		@media(min-width:$screen-xs) {
			font-size: 40px;
		}

	}
	.heading {
		text-align: center;
		display: block;
		margin: 0 auto 0px;
	}

}

.wrapper {



}
.box-container {
	text-align: center;
	color: black;
	margin: 0 auto;
	height: 100%;
	overflow: hidden;

	&.show {
		opacity: 0;
	}

	.display-message {
		display: none;
		margin-top: 0px;
		color: white;
		width: 100%;
		top: 0;
		padding-top: 20px;
		height:100%;
		z-index: 50;
		position: absolute;
		background: #281e17 url("../images/error.gif") no-repeat center center;
		text-transform: uppercase;

		@media(min-width:$screen-xs) and (max-height: 70vw) {
				display: block;
		}
		@media(min-width:$screen-sm) and (orientation: landscape)  {
			display: none;
		}
		@media (min-width:$screen-md) and (max-height: 500px){
			display: block;
		}
	}
	.box {
		z-index: 20;
		padding-bottom: 20px;
		width: 100%;



		.box-holder{

			min-height:300px;
			height: 83vh;
			margin: 0 auto;
			margin-top: -70px;
			position: relative;
			@media(min-width:$screen-sm) {
				height:85vh;
				min-height:400px;
			}
			@media(min-width:$screen-lg) {
				height:85vh;
				min-height:500px;
			}


		}

		img {
			height:100%;
		}


	}
	.player{
		position: absolute;
		top:65%;
		width: 100%;
		text-transform: uppercase;
		font-family: $font-family-special;
		font-size: 25px;
		text-shadow:1px 1px 3px rgba(0,0,0, 0.3);
		@media(min-width:$screen-sm) {
			top:65%;
			font-size: 20px;

		}

		.player-display {
			color:  #534133;
			font-size:1.2em;


		}

		.player-label {
			display: none;
			@media(min-width:$screen-sm) {
				display: inline;
			}
		}
	}
	.start-btn {
		margin-top: -10px;
		@media(min-width:$screen-sm) {
			margin-top: 0px;
		}
	}

}


.reset-btn {
	margin-top:0px;
	text-align: center;
}


#items {
	text-align: center;
	margin: 0 auto;
	width: 100%;
	height: 0px;
	position: relative;
	display: block;

	.match-holder #matchArea{
		transform: translateY(200px);
		transition: all 0.25s ease 0.5s;
	}
	&.show {
		position: static;
		.match-holder #matchArea{
			transform: translateY(0px);
			opacity: 1;
		}
		.match-holder{
			bottom:0;
		}
		#matchArea {
			opacity: 1;
		}
		.boxoverlay {
			opacity: 1;
		}
	}
	@media(min-width:$screen-md) {
		width: 100%;
	}


	.match-holder{
		position: absolute;
		width: 100%;
		bottom: -100px;
		opacity: 1;
		transition: all 0.15s ease 0s;
		

	}
	#matchArea {
		margin-top: 0;
		width: 100%;
		opacity: 0;
	}
	.boxoverlay {
		height: 100px;
		position: absolute;
		width: 100%;
		bottom:0;
		background: transparent url('../images/overlay_xs.gif') no-repeat center 0px;
		background-size:100%;
		opacity: 0;
		transition: all 0.1s ease-in 0s;
		@media(min-width:$screen-sm) {
			background-size: 500px;

		}

	}
	&.show .boxoverlay {
		height: 100px;

		@media(min-width:$screen-sm) {
			height: 150px;
			background: transparent url('../images/overlay.png') no-repeat center 0px;
		}
		@media (min-width:$screen-xs)and (orientation: landscape) {
			height:150px;
		}
		@media (min-width:$screen-xs)and (orientation: portrait) {
			height:150px;
		}


		@media(min-width:$screen-md) and (orientation: landscape) {
			height:150px;
		}
	}
	/*Streichholz*/
	.item {
		margin-top: 150px;
		display: inline-block;
		width: 28px;
		height: 200px;
		@media(min-width:$screen-xs) {
			width: 28px;
			height: 250px;
		}
		@media(min-width:$screen-sm) {
			width: 30px;
			height: 300px;
			margin-left: 8px;
			margin-right: 8px;
		}
		background: transparent url("../images/match.png") no-repeat  center top;

		background-size:cover;
		margin-left: 5px;
		margin-right: 5px;
		&.short {
			background: transparent url("../images/match-short.png") no-repeat center top;
			background-size:cover;
		}
		&:hover {

			cursor: pointer;
		}

		&.active {
			margin-bottom: 120px;
			margin-top: 0;
			cursor: default;
			transition: all 0.25s ease 0s;
			@media(min-width:$screen-sm) {
				margin-bottom: 200px;
			}
			@media(min-width:$screen-xs) and (orientation: landscape) {
				margin-bottom: 80px;
			}
			@media(min-width:$screen-sm) and (orientation: landscape) {
				margin-bottom: 200px;
			}

		}
	}

}

.looser {
	z-index: 10;
	width: 100%;
	position: absolute;
	top:150px;
	transition: all 0.25s ease 0.5s;
	.loose-message {
		img {
			@media(min-width:$screen-sm) {
				width: 400px;
			}
		}
		display: block;
	}
}


.footer {
	width: 100%;
	z-index:100;
	text-align: center;
	padding: 0;
	position: fixed;
	bottom: 0;
	.btn {
		margin-top: 10px;
		margin-bottom:10px;
		@media(min-width:$screen-sm) {
			margin-bottom:30px;
		}
	}
	.bottom {
		text-align: center;
		padding: 10px 0px;
		overflow: hidden;
		color: #c3bcb1;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#30241c+0,000000+100 */
		background: rgb(48,36,28); /* Old browsers */
		background: -moz-linear-gradient(top,  rgba(48,36,28,1) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(48,36,28,1) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(48,36,28,1) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30241c', endColorstr='#000000',GradientType=0 ); /* IE6-9 */

		h2 {
			display: inline;

			font-size: 10px;
			line-height:30px;
			text-align: center;
			text-transform: uppercase;
			font-weight:bold;
			margin:0;

			font-family: $font-family-base;
			a {
				color: #c3bcb1;
			}
		}
		
		.start-btn,.nav-link {
			display: block;
			float: right;
			margin-top: 0px;
			cursor: pointer;
			font-size: 15px;
			line-height:30px;
			background:  #c3bcb1;

			box-shadow: 1px 1px 6px rgba(0, 0, 0, .7);
			height: 30px;
			width: 30px;
			border-radius: 30px;
			text-align: center;
			text-transform: uppercase;
			@media(min-width:$screen-sm) {
				height: 40px;
				width: 40px;
				border-radius: 460px;
				font-size: 20px;
				line-height:40px;
			}
			&:hover {
				background: white;
			}

				float: right;

		}
		.start-btn {
			float: left;
			opacity: 0;
			&.show {
				opacity: 1;
			}
		}
	}
}

