/*******************************************************************************
*
* _fonts.scss
*
* ******************************************************************************/



/* Font Awesome siehe fontawesome-ORdner*/



/* Corporate Fonts */
@font-face{
  font-family: 'SimplonMono-Medium-WebXL';
  src: url('../fonts/simplon/mono/SimplonMono-Medium-WebXL.eot');
  src: url('../fonts/simplon/mono/SimplonMono-Medium-WebXL.eot?#iefix') format('embedded-opentype'),
  url('../fonts/simplon/mono/SimplonMono-Medium-WebXL.woff2') format('woff2'),
  url('../fonts/simplon/mono/SimplonMono-Medium-WebXL.woff') format('woff'),
  url('../fonts/simplon/mono/SimplonMono-Medium-WebXL.ttf') format('truetype'),
  url('../fonts/simplon/mono/SimplonMono-Medium-WebXL.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'SimplonMono-Regular-WebXL';
  src: url('../fonts/simplon/mono/SimplonMono-Regular-WebXL.eot');
  src: url('../fonts/simplon/mono/SimplonMono-Regular-WebXL.eot?#iefix') format('embedded-opentype'),
  url('../fonts/simplon/mono/SimplonMono-Regular-WebXL.woff2') format('woff2'),
  url('../fonts/simplon/mono/SimplonMono-Regular-WebXL.woff') format('woff'),
  url('../fonts/simplon/mono/SimplonMono-Regularm-WebXL.ttf') format('truetype'),
  url('../fonts/simplon/mono/SimplonMono-Regular-WebXL.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'SimplonNorm-Light-WebXL';
  src: url('../fonts/simplon/norm/SimplonNorm-Light-WebXL.eot');
  src: url('../fonts/simplon/norm/SimplonNorm-Light-WebXL.eot?#iefix') format('embedded-opentype'),
  url('../fonts/simplon/norm/SimplonNorm-Light-WebXL.woff2') format('woff2'),
  url('../fonts/simplon/norm/SimplonNorm-Light-WebXL.woff') format('woff'),
  url('../fonts/simplon/norm/SimplonNorm-Light-WebXL.ttf') format('truetype'),
  url('../fonts/simplonnorm/SimplonNorm-Light-WebXLL.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'SimplonNorm-LightItalic-WebXL';
  src: url('../fonts/simplon/norm/SimplonNorm-LightItalic-WebXL.eot');
  src: url('../fonts/simplon/norm/SimplonNorm-LightItalic-WebXL.eot?#iefix') format('embedded-opentype'),
  url('../fonts/simplon/norm/SimplonNorm-LightItalic-WebXL.woff2') format('woff2'),
  url('../fonts/simplon/norm/SimplonNorm-LightItalic-WebXL.woff') format('woff'),
  url('../fonts/simplon/norm/SimplonNorm-LightItalic-WebXL.ttf') format('truetype'),
  url('../fonts/simplonnorm/SimplonNorm-LightItalic-WebXLL.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'SimplonNorm-Medium-WebXL';
  src: url('../fonts/simplon/norm/SimplonNorm-Medium-WebXL.eot');
  src: url('../fonts/simplon/norm/SimplonNorm-Medium-WebXL.eot?#iefix') format('embedded-opentype'),
  url('../fonts/simplon/norm/SimplonNorm-Medium-WebXL.woff2') format('woff2'),
  url('../fonts/simplon/norm/SimplonNorm-Medium-WebXL.woff') format('woff'),
  url('../fonts/simplon/norm/SimplonNorm-Medium-WebXL.ttf') format('truetype'),
  url('../fonts/simplonnorm/SimplonNorm-Medium-WebXLL.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face{
  font-family: 'SimplonNorm-Regular-WebXL';
  src: url('../fonts/simplon/norm/SimplonNorm-Regular-WebXL.eot');
  src: url('../fonts/simplon/norm/SimplonNorm-Regular-WebXL.eot?#iefix') format('embedded-opentype'),
  url('../fonts/simplon/norm/SimplonNorm-Regular-WebXL.woff2') format('woff2'),
  url('../fonts/simplon/norm/SimplonNorm-Regular-WebXL.woff') format('woff'),
  url('../fonts/simplon/norm/SimplonNorm-Regular-WebXL.ttf') format('truetype'),
  url('../fonts/simplonnorm/SimplonNorm-Regular-WebXLL.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}





@font-face{
    font-family: 'tilderegular';
    src: url('../fonts/tilde/tilde-regular.eot');
    src: url('../fonts/tilde/tilde-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/tilde/tilde-regular.woff2') format('woff2'),
         url('../fonts/tilde/tilde-regular.woff') format('woff'),
         url('../fonts/tilde/tilde-regular.ttf') format('../fonts/tilde/truetype'),
         url('../fonts/tilde/tilde-regular.svg#tilderegular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'tildelight';
    src: url('../fonts/tilde/tilde-light.eot');
    src: url('../fonts/tilde/tilde-light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/tilde/tilde-light.woff2') format('woff2'),
         url('../fonts/tilde/tilde-light.woff') format('woff'),
         url('../fonts/tilde/tilde-light.ttf') format('../fonts/tilde/truetype'),
         url('../fonts/tilde/tilde-light.svg#tildelight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'tildeitalic';
    src: url('../fonts/tilde/tilde-italic.eot');
    src: url('../fonts/tilde/tilde-italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/tilde/tilde-italic.woff2') format('woff2'),
         url('../fonts/tilde/tilde-italic.woff') format('woff'),
         url('../fonts/tilde/tilde-italic.ttf') format('../fonts/tilde/truetype'),
         url('../fonts/tilde/tilde-italic.svg#tildeitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'tildelight_italic';
    src: url('../fonts/tilde/tilde-lightitalic.eot');
    src: url('../fonts/tilde/tilde-lightitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/tilde/tilde-lightitalic.woff2') format('woff2'),
         url('../fonts/tilde/tilde-lightitalic.woff') format('woff'),
         url('../fonts/tilde/tilde-lightitalic.ttf') format('../fonts/tilde/truetype'),
         url('../fonts/tilde/tilde-lightitalic.svg#tildelight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'tildebold_italic';
    src: url('../fonts/tilde/tilde-bolditalic.eot');
    src: url('../fonts/tilde/tilde-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/tilde/tilde-bolditalic.woff2') format('woff2'),
         url('../fonts/tilde/tilde-bolditalic.woff') format('woff'),
         url('../fonts/tilde/tilde-bolditalic.ttf') format('../fonts/tilde/truetype'),
         url('../fonts/tilde/tilde-bolditalic.svg#tildebold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'tildebold';
    src: url('../fonts/tilde/tilde-bold.eot');
    src: url('../fonts/tilde/tilde-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/tilde/tilde-bold.woff2') format('woff2'),
         url('../fonts/tilde/tilde-bold.woff') format('woff'),
         url('../fonts/tilde/tilde-bold.ttf') format('../fonts/tilde/truetype'),
         url('../fonts/tilde/tilde-bold.svg#tildebold') format('svg');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'tildeblack';
    src: url('../fonts/tilde/tilde-black.eot');
    src: url('../fonts/tilde/tilde-black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/tilde/tilde-black.woff2') format('woff2'),
         url('../fonts/tilde/tilde-black.woff') format('woff'),
         url('../fonts/tilde/tilde-black.ttf') format('../fonts/tilde/truetype'),
         url('../fonts/tilde/tilde-black.svg#tildeblack') format('svg');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'tildeblack_italic';
    src: url('../fonts/tilde/tilde-blackitalic.eot');
    src: url('../fonts/tilde/tilde-blackitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/tilde/tilde-blackitalic.woff2') format('woff2'),
         url('../fonts/tilde/tilde-blackitalic.woff') format('woff'),
         url('../fonts/tilde/tilde-blackitalic.ttf') format('../fonts/tilde/truetype'),
         url('../fonts/tilde/tilde-blackitalic.svg#tildeblack_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}