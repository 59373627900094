/*******************************************************************************
*
* _media.scss
*
******************************************************************************/

img, media {
  max-width: 100%;
}


.image a {
	@include noLink;	
}

.image ul {
	@include noList; 	
}


