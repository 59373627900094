/*******************************************************************************
*
* _lists.scss
*
******************************************************************************/


ul {
	@include ulList; 
}

ol {
	@include olList; 
}



.menu, 
#menu ul{
	@include noList; 
}
