/*******************************************************************************
*
* _mixins-lists.scss
*
******************************************************************************/


/* Listen ohne Abstände und Aufzählungszeichen */
@mixin noList {
  padding: 0;
  margin: 0;
  list-style-type: none !important;
  list-style-image: none !important;
  letter-spacing: $letter-spacing-base;
  text-indent:0;
  li {
    padding: 0;
    margin: 0;
    list-style-type: none !important;
	list-style-image: none !important;
	line-height: normal;
    &::before {
      margin: 0;
      content: '';
      display: inline;
    }

  } 
}


@mixin ulList {
  letter-spacing: $letter-spacing-base;
  padding-left: 1em;
  text-indent: 0.5em;
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  li::before {
    content: "–";
    display: inline-block;
    margin-right: 10px;

  }
}

@mixin olList {
  letter-spacing: $letter-spacing-base;
  padding-left: 1em;
  text-indent: 0.5em;


}

 
