/*******************************************************************************
*
* _buttons.scss
*
******************************************************************************/


.btn {
  border: none;
  border-radius: 5px;
  width: auto;
  padding-top: 10px;
  text-transform: uppercase;
  font-family: $font-family-special;
  font-size: 20px;
  &.btn-primary {
    background: darken(#bf655b, 5%);
    box-shadow: 1px 1px 6px rgba(0, 0, 0, .7);
    &:hover {
      background:   darken(#bf655b, 10%);
    }
    &:active, &:focus {
      box-shadow: none;

    }


  }
  &.btn-info {
    background:   darken(#979270, 5%);
    box-shadow: 1px 1px 6px rgba(0, 0, 0, .7);
    &:hover, &:active, &:focus, &.focus  {
      background:   darken(#979270, 10%);
      box-shadow: none;
    }
  }


}


#left-btn,
#right-btn {
  color: #bf655b;
  cursor: pointer;
  padding: 15px;
  opacity: 1;
  font-size: 30px;
  line-height:20px;
  &:hover, &:focus {
    color:   darken(#bf655b, 10%);
   text-shadow: none !important;
  }
}
