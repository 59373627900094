/*****************************************************************************
*
* _mixins-headlines.scss
*
******************************************************************************/


@mixin noheadline {
	margin: 0;
	padding: 0;
	font-size:$font-size-base;
	font-weight: normal;
	letter-spacing: normal;
	font-family: $font-family-base;
	text-transform: none;
	line-height:$line-height-base;
}



@mixin default-headlines {
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;

}


@mixin headline-h1 {
	font-family: $font-family-special;
	font-weight: normal;

	padding: 0;
	margin-bottom: 10px;
	margin-top:0;


}

@mixin headline-h2 {


}

@mixin headline-h3 {


}

@mixin headline-h4 {
}

@mixin headline-h5 {
}

@mixin headline-h6 {
}


