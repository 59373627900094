/*******************************************************************************
* 
* _typography.scss
*
******************************************************************************/

body {


	/* Ueberschriften */
	h1, h2, h3, h4, h5, h6 {
	
	}
	 
	h1, .h1 {
		@include headline-h1;
	}


	h2,.h2 {
		@include headline-h2;

	}

	
	h3,.h3 {
		@include headline-h3;
	}

	
	h4,.h4 {
		@include headline-h4;
	}
	
	h5, .h5 {
		@include headline-h5;
	}
	
	h6, .h6 {
		@include headline-h6;
	}

	h3.slogan, .slogan
	{

	}




	


}