/*******************************************************************************
*
* _links.scss
*
******************************************************************************/





p a {
	@include link;
}

a.logo {
	@include noLink;
}

a.no-link {
	@include noLink;	
}



a.internal-link {
	color: $color3 !important;
	transition: all 0.25s ease 0s;

	i.si {
		transition: all 0.25s ease 0s;
	}
	i.si:before {
		content: '→';

	}
	&:hover {
		color: $color1 !important;
		i.si {
			margin-left: 3px;
		}
	}
}
a.link {
	color: $color3 !important;
	&::after {
		content: '→';
		margin-left: 3px;
		transition: all 0.25s ease 0s;

	}
	&:hover::after {
		margin-left: 7px;
	}
	&:hover, &:focus, &:active {
		color: $color4 !important;
		border: none;
		text-decoration: none;
	}
}



a.open-link {
	color: $color3 !important;
	font-size: $font-size-small;
	font-family: "SimplonMono-Regular-WebXL", Helvetica, Arial, sans-serif;
	text-decoration: none;
	transition: all 0.25s ease 0s;
	border-bottom: 1px solid $color3;
	text-transform: uppercase;
	i.si {
		width: 9px;
		height: 9px;
		background: transparent url(../images/icon-open-link.svg) no-repeat center center;
	}
	&:hover {
		border-bottom: none  !important;
	}
}


a.close-link {
	color: $color2 !important;
	font-size: $font-size-small;
	font-family: "SimplonMono-Regular-WebXL", Helvetica, Arial, sans-serif;
	text-decoration: none;

	border-bottom: 1px solid $color2;
	text-transform: uppercase;
	i.si {
		width: 9px;
		height: 9px;
		background: transparent url(../images/icon-close-link.svg) no-repeat center center;
	}

	&:hover {
		border-bottom: none !important;
	}
}

